var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shequFw_content"},[_c('div',{staticClass:"shequFw_content_item"},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"t_l"},[_vm._v(_vm._s(_vm.getdatavalue1(60)))]),_c('span',{staticClass:"t_r"},[_vm._v("人")])]),_vm._m(0)]),_c('div',{staticClass:"shequFw_content_item"},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"t_l"},[_vm._v(_vm._s(_vm.getdatavalue1(321)))]),_c('span',{staticClass:"t_r"},[_vm._v("个")])]),_vm._m(1)]),_c('div',{staticClass:"shequFw_content_item"},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"t_l"},[_vm._v(_vm._s(_vm.getdatavalue1(322)))]),_c('span',{staticClass:"t_r"},[_vm._v("个")])]),_vm._m(2)]),_c('div',{staticClass:"shequFw_content_item"},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"t_l"},[_vm._v(_vm._s(_vm.getdatavalue(128)))]),_c('span',{staticClass:"t_r"},[_vm._v("项")])]),_vm._m(3)]),_c('div',{staticClass:"shequFw_content_item"},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"t_l"},[_vm._v(_vm._s(_vm.getdatavalue1(62)))]),_c('span',{staticClass:"t_r"},[_vm._v("位")])]),_vm._m(4)]),_c('div',{staticClass:"shequFw_content_item"},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"t_l"},[_vm._v(_vm._s(_vm.getdatavalue1(323)))]),_c('span',{staticClass:"t_r"},[_vm._v("个")])]),_vm._m(5)]),_c('div',{staticClass:"shequFw_content_item"},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"t_l"},[_vm._v(_vm._s(_vm.getdatavalue1(324)))]),_c('span',{staticClass:"t_r"},[_vm._v("项")])]),_vm._m(6)]),_c('div',{staticClass:"shequFw_content_item"},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"t_l"},[_vm._v(_vm._s(_vm.getdatavalue1(325)))]),_c('span',{staticClass:"t_r"},[_vm._v("个")])]),_vm._m(7)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('span',{staticClass:"t_r"},[_vm._v("街道驻社区团队")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('span',{staticClass:"t_r"},[_vm._v("党群服务中心")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('span',{staticClass:"t_r"},[_vm._v("党建阵地")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('span',{staticClass:"t_r"},[_vm._v("窗口政务服务事项")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('span',{staticClass:"t_r"},[_vm._v("在册志愿者")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('span',{staticClass:"t_r"},[_vm._v("社会组织")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('span',{staticClass:"t_r"},[_vm._v("自助服务事项")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('span',{staticClass:"t_r"},[_vm._v("成立业委会的住宅小区")])])
}]

export { render, staticRenderFns }