<template>
  <div class="idx-right-box2">
    <div class="idx-Rbox-1">
      <router-link to="/judicialMediation">东泰社区司法调解汇总</router-link>
    </div>
    <div class="idx-Rbox-2" @click="go">
      <div class="idx-Rbox-2a">
        <span class="idx-rs-1">{{ getdatavalue(63) }}</span>
        <span class="idx-rs-2">全部事件</span>
      </div>
      <div class="idx-Rbox-2a">
        <span class="idx-rs-1">{{ getdatavalue(64) }}</span>
        <span class="idx-rs-2">处理中</span>
      </div>
      <div class="idx-Rbox-2a">
        <span class="idx-rs-1">{{ getdatavalue(65) }}</span>
        <span class="idx-rs-2">处理率</span>
      </div>
    </div>
    <!--东泰社区司法调解汇总  -->
    <div class="idx-Rbox-3" id="tiaoJie"></div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  props: ["dialog_click"],
  data() {
    return {
      ydata: [],
      xdata: [],
      dataOptions: [],
      dataOptions1: [],
      queryParams: {
        dataModule: "sysj",
        dataTyp: "sysj-sftjhz",
      },
      queryParams1: {
        dataModule: "sysj",
        dataTyp: "sysj-sftjfl",
      },
    };
  },
  created() {
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
    });

    listData(this.queryParams1).then((response) => {
      for (let i = 0; i < response.rows.length; i++) {
        this.ydata[i] = {};
        //  this.ydata[i].name = response.rows[i].dataName;
        this.ydata[i] = parseInt(response.rows[i].dataDetailed);
        this.xdata[i] = response.rows[i].dataName;
        // console.log(this.ydata[i].name+':'+this.ydata[i].value);
      }
      this.dataOptions1 = response.rows
      this.tiaoJie();
    });
    //   this.dangyuanData()
  },
  methods: {
    go() {
      let arr1 = this.dataOptions1.map(item=>{
        return {
          dataDetailed: item.dataDetailed,
          dataId: item.dataId,
          label: item.dataName
        }
      })
      let arr = [
        { dataId: '63',label: "全部事件",dataDetailed: this.getdatavalue(63) },
        { dataId: '64',label: "处理中",dataDetailed: this.getdatavalue(64) },
        { dataId: '65',label: "处理率",dataDetailed: this.getdatavalue(65) },
        ...arr1
      ];
      console.log(arr);
      this.$emit("dialog_click","东泰社区司法调解汇总", arr);
    },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
    tiaoJie() {
      if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        let tiaoJiet = this.$echarts.init(document.getElementById("tiaoJie"));
        //   let myChart = this.$echarts.init(document.getElementById("main"));
        let baifenbi = this.ydata; //[4, 56, 35, 228, 21, 147, 90];
        // let grayBar = [300, 300, 300, 300, 300, 300, 300];
        let grayBar = [1000, 1000, 1000, 1000, 1000, 1000, 1000];
        let xingm = this.xdata;
        let option = {
          title: {
            text: "",
            left: "20%",
            top: "20%",
            textStyle: {
              color: "#fff",
              fontSize: 10,
            },
          },
          grid: {
            left: "25%", //如果离左边太远就用这个......
            right: "18%",
            bottom: "35%",
            top: "25%",
            containLabel: true,
          },
          xAxis: [
            {
              show: false,
            },
            {
              show: false,
            },
          ],
          yAxis: {
            type: "category",
            axisLabel: {
              show: true, //让Y轴数据不显示
            },

            axisTick: {
              show: false, //隐藏Y轴刻度
            },
            axisLine: {
              show: false, //隐藏Y轴线段
            },
          },
          series: [
            //背景色
            {
              show: true,
              type: "bar",
              barGap: "-100%",
              barWidth: "35%", //统计条宽度
              itemStyle: {
                normal: {
                  barBorderRadius: 150,
                  fontSize: 10,
                  color: "rgba(41, 55, 94)",
                },
              },
              label: {
                normal: {
                  show: true,
                  textStyle: {
                    color: "#fff", //百分比颜色
                    fontSize: 12,
                  },
                  position: "right",
                  formatter: function (data) {
                    return baifenbi[data.dataIndex];
                  },
                  // formatter: function(data) {
                  // 	return baifenbi[data.dataIndex]+'%'
                  // },
                },
              },
              z: 1,
              data: grayBar,
            },
            //蓝条
            {
              show: true,
              type: "bar",
              barGap: "-100%",
              barWidth: "35%", //统计条宽度
              itemStyle: {
                normal: {
                  barBorderRadius: 50, //统计条弧度
                  color: {
                    colorStops: [
                      {
                        offset: 0,
                        color: "#025EEA", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#00C0FA", // 100% 处的颜色
                      },
                    ],
                    globalCoord: false, // 缺省为 false
                  },
                },
              },
              max: 1,
              label: {
                normal: {
                  show: true,
                  textStyle: {
                    fontSize: 10,
                    color: "#fff", //百分比颜色
                  },
                  position: "left",
                  formatter: function (data) {
                    //富文本固定格式{colorName|这里填你想要写的内容}
                    return xingm[data.dataIndex];
                  },
                },
              },
              data: baifenbi,
            },
          ],
        };
        tiaoJiet.setOption(option);
      } else {
        let tiaoJiet = this.$echarts.init(document.getElementById("tiaoJie"));
        //   let myChart = this.$echarts.init(document.getElementById("main"));
        let baifenbi = this.ydata; //[4, 56, 35, 228, 21, 147, 90];
        // let grayBar = [300, 300, 300, 300, 300, 300, 300];
        let grayBar = [1000, 1000, 1000, 1000, 1000, 1000, 1000];
        let xingm = this.xdata;
        let option = {
          title: {
            text: "",
            left: "20%",
            top: "20%",
            textStyle: {
              color: "#fff",
              fontSize: 12,
            },
          },
          grid: {
            left: "20%", //如果离左边太远就用这个......
            right: "18%",
            bottom: "35%",
            top: "25%",
            containLabel: true,
          },
          xAxis: [
            {
              show: false,
            },
            {
              show: false,
            },
          ],
          yAxis: {
            type: "category",
            axisLabel: {
              show: true, //让Y轴数据不显示
            },

            axisTick: {
              show: false, //隐藏Y轴刻度
            },
            axisLine: {
              show: false, //隐藏Y轴线段
            },
          },
          series: [
            //背景色
            {
              show: true,
              type: "bar",
              barGap: "-100%",
              barWidth: "35%", //统计条宽度
              itemStyle: {
                normal: {
                  barBorderRadius: 150,
                  fontSize: 14,
                  color: "rgba(41, 55, 94)",
                },
              },
              label: {
                normal: {
                  show: true,
                  textStyle: {
                    color: "#fff", //百分比颜色
                    fontSize: 14,
                  },
                  position: "right",
                  formatter: function (data) {
                    return baifenbi[data.dataIndex];
                  },
                  // formatter: function(data) {
                  // 	return baifenbi[data.dataIndex]+'%'
                  // },
                },
              },
              z: 1,
              data: grayBar,
            },
            //蓝条
            {
              show: true,
              type: "bar",
              barGap: "-100%",
              barWidth: "35%", //统计条宽度
              itemStyle: {
                normal: {
                  barBorderRadius: 50, //统计条弧度
                  color: {
                    colorStops: [
                      {
                        offset: 0,
                        color: "#025EEA", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#00C0FA", // 100% 处的颜色
                      },
                    ],
                    globalCoord: false, // 缺省为 false
                  },
                },
              },
              max: 1,
              label: {
                normal: {
                  show: true,
                  textStyle: {
                    fontSize: 14,
                    color: "#fff", //百分比颜色
                  },
                  position: "left",
                  formatter: function (data) {
                    //富文本固定格式{colorName|这里填你想要写的内容}
                    return xingm[data.dataIndex];
                  },
                },
              },
              data: baifenbi,
            },
          ],
        };
        tiaoJiet.setOption(option);
      }
    },
  },
  mounted() {
    // this.tiaoJie();
    // this.drawChart();
  },
};
</script>

<style scoped lang="scss">
.idx-right-box2 {
  width: 100%;
  height: 33vh;
  overflow: hidden;
  // border: 1px solid tan;
  .idx-Rbox-1 {
    font-size: 0.9vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #04f3ee;
    padding-left: 1.3vw;
    padding-top: 2vh;
    position: relative;
    z-index: 99;
    a {
      color: #04f3ee;
    }
  }
  .idx-Rbox-2 {
    width: 77%;
    height: 4vh;
    line-height: 3vh;
    // border: 1px solid tan;
    font-size: 0.9vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    margin: 0 auto;
    margin-top: 3vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .idx-Rbox-2a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 99999;
      .idx-rs-1 {
        font-size: 1.6vw;
        font-family: hy;
        font-weight: bold;
        font-style: italic;
        color: #ffd901;
      }
      .idx-rs-2 {
        font-size: 0.8vw;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #eeab1a;
      }
    }
  }
  .idx-Rbox-3 {
    width: 100%;
    height: 55vh;
    // border: 1px solid teal;
    margin-top: -12vh;
  }
}
</style>
