<template>
  <div class="idx-right3-box2">
    <div class="idx-Rbox-1">
      <router-link to="/yiQing">疫情防控数据</router-link>
    </div>

    <!--疫情防控数据  -->
    <div class="idx-Rbox-3" id="yiqing" @click="go"></div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  props: ["dialog_click"],
  data() {
    return {
      ydata: [],
      xdata: [],
      dataOptions: [],
      queryParams: {
        dataModule: "sysj",
        dataTyp: "sysj-dyhz",
      },
      queryParams1: {
        dataModule: "sysj",
        dataTyp: "sysj-yqfk",
      },
    };
  },
  created() {
    listData(this.queryParams1).then((response) => {
      for (let i = 0; i < response.rows.length; i++) {
        this.ydata[i] = {};
        this.ydata[i].name = response.rows[i].dataName;
        this.ydata[i].value = parseInt(response.rows[i].dataDetailed);
        this.xdata[i] = response.rows[i].dataName;
        // console.log(this.ydata[i].name+':'+this.ydata[i].value);
      }
      this.dataOptions = response.rows
      // console.log("疫情防控数据",this.xdata,this.ydata);
      this.yiqing();
    });
    //   this.dangyuanData()
  },
  methods: {
    go() {
      let arr = this.dataOptions.map(item=>{
        return {
          dataDetailed: item.dataDetailed,
          dataId: item.dataId,
          label: item.dataName
        }
      })
      this.$emit("dialog_click","疫情防控数据",arr);
    },
    // 疫情防控数据环形
    yiqing() {
      // 判断pc iPad
      if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        let myChart = this.$echarts.init(document.getElementById("yiqing"));
        // let xdata = ["社区健康管理", "集中隔离", "居家隔离"];
        let ydata = this.ydata;
        let color = ["#2363F1", "#F56B03", "#24D8D7"];
        // let color = ["#25F3E6", "#FF7F50", "#DA70D6"]
        // let color = ["#87CEFA", "#FF7F50", "#DA70D6"]
        let xdata = this.xdata;

        let option = {
          /*backgroundColor: "rgba(255,255,255,1)",*/
          title: {
            //   text: "疫情防控数据",
            subtext: "",
            textStyle: {
              color: "#FFFFFF",
              fontSize: 14, //文字的字体大小
            },
            left: "23%",
            top: "85.5%",
          },
          color: color,
          legend: {
            orient: "vertical",
            top: "30%",
            left: "57%",
            bottom: "0%",
            data: xdata,
            itemWidth: 10,
            itemHeight: 10,
            textStyle: {
              //图例文字的样式
              color: "#fff",
              fontSize: 12,
              padding: 3,
            },
            /*itemGap: 16,*/
            /*formatter:function(name){
				  let oa = option.series[0].data;
				  let num = oa[0].value + oa[1].value + oa[2].value + oa[3].value+oa[4].value + oa[5].value + oa[6].value + oa[7].value+oa[8].value + oa[9].value ;
				  for(let i = 0; i < option.series[0].data.length; i++){
				      if(name==oa[i].name){
				          return ' '+name + '    |    ' + oa[i].value + '    |    ' + (oa[i].value/num * 100).toFixed(2) + '%';
				      }
				  }
				}*/

            formatter: function (name) {
              return "" + name;
            },
          },
          series: [
            {
              type: "pie",
              clockwise: false, //饼图的扇区是否是顺时针排布
              minAngle: 2, //最小的扇区角度（0 ~ 360）
              // radius: ["45%", "55%"],
              // center: ["30%", "53%"],
              radius: ["60%", "70%"],
              center: ["30%", "53%"],
              avoidLabelOverlap: false,
              itemStyle: {
                //图形样式
                normal: {
                  // borderColor: "#ffffff",
                  borderWidth: 1,
                },
              },
              label: {
                normal: {
                  show: false,
                  position: "center",
                  formatter: "{text|{b}}\n{c}",
                  // formatter: '{text|{b}}\n {text|{c} ({d}%)}',
                  // formatter: '{text|{b}}\n{c} ({d}%)',
                  rich: {
                    text: {
                      color: "#fff",
                      fontSize: 12,
                      align: "center",
                      verticalAlign: "middle",
                      padding: 8,
                    },
                    value: {
                      color: "#fff",
                      fontSize: 12,
                      align: "center",
                      verticalAlign: "middle",
                    },
                  },
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: 12,
                  },
                },
              },

              data: ydata,
            },
          ],
        };
        myChart.setOption(option);

        setTimeout(function () {
          myChart.on("click", function (params) {
            dzbMap(params.name);
          });
          myChart.on("mouseover", function (params) {
            // window.clearInterval(setInterval())
            // clearInterval(qq);
            if (params.name == ydata[0].name) {
              myChart.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: 0,
              });
            } else {
              myChart.dispatchAction({
                type: "downplay",
                seriesIndex: 0,
                dataIndex: 0,
              });
            }
          });

          myChart.on("mouseout", function (params) {
            // setInterval(qq)
            myChart.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: 0,
            });
          });
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: 0,
          });
        }, 3000);

        myChart.currentIndex = -1;

        let qq = setInterval(function () {
          let dataLen = option.series[0].data.length;
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: myChart.currentIndex,
          });
          myChart.currentIndex = (myChart.currentIndex + 1) % dataLen;
          // 高亮当前图形
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: myChart.currentIndex,
          });
        }, 3000);

        // 使用刚指定的配置项和数据显示图表。
        /*myChart.setOption(option);*/
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      } else {
        let myChart = this.$echarts.init(document.getElementById("yiqing"));
        // let xdata = ["社区健康管理", "集中隔离", "居家隔离"];
        let ydata = this.ydata;
        let color = ["#2363F1", "#F56B03", "#24D8D7"];
        // let color = ["#25F3E6", "#FF7F50", "#DA70D6"]
        // let color = ["#87CEFA", "#FF7F50", "#DA70D6"]
        let xdata = this.xdata;

        let option = {
          /*backgroundColor: "rgba(255,255,255,1)",*/
          title: {
            //   text: "疫情防控数据",
            subtext: "",
            textStyle: {
              color: "#FFFFFF",
              fontSize: 14, //文字的字体大小
            },
            left: "23%",
            top: "85.5%",
          },
          color: color,
          legend: {
            orient: "vertical",
            top: "30%",
            right: "4%",
            bottom: "0%",
            data: xdata,
            itemWidth: 10,
            itemHeight: 10,
            textStyle: {
              //图例文字的样式
              color: "#fff",
              fontSize: 14,
              padding: 8,
            },
            /*itemGap: 16,*/
            /*formatter:function(name){
				  let oa = option.series[0].data;
				  let num = oa[0].value + oa[1].value + oa[2].value + oa[3].value+oa[4].value + oa[5].value + oa[6].value + oa[7].value+oa[8].value + oa[9].value ;
				  for(let i = 0; i < option.series[0].data.length; i++){
				      if(name==oa[i].name){
				          return ' '+name + '    |    ' + oa[i].value + '    |    ' + (oa[i].value/num * 100).toFixed(2) + '%';
				      }
				  }
				}*/

            formatter: function (name) {
              return "" + name;
            },
          },
          series: [
            {
              type: "pie",
              clockwise: false, //饼图的扇区是否是顺时针排布
              minAngle: 2, //最小的扇区角度（0 ~ 360）
              // radius: ["45%", "55%"],
              // center: ["30%", "53%"],
              radius: ["55%", "75%"],
              center: ["30%", "53%"],
              avoidLabelOverlap: false,
              itemStyle: {
                //图形样式
                normal: {
                  // borderColor: "#ffffff",
                  borderWidth: 1,
                },
              },
              label: {
                normal: {
                  show: false,
                  position: "center",
                  formatter: "{text|{b}}\n{c}",
                  // formatter: '{text|{b}}\n {text|{c} ({d}%)}',
                  // formatter: '{text|{b}}\n{c} ({d}%)',
                  rich: {
                    text: {
                      color: "#fff",
                      fontSize: 16,
                      align: "center",
                      verticalAlign: "middle",
                      padding: 8,
                    },
                    value: {
                      color: "#fff",
                      fontSize: 16,
                      align: "center",
                      verticalAlign: "middle",
                    },
                  },
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: 16,
                  },
                },
              },

              data: ydata,
            },
          ],
        };
        myChart.setOption(option);

        setTimeout(function () {
          myChart.on("click", function (params) {
            dzbMap(params.name);
          });
          myChart.on("mouseover", function (params) {
            // window.clearInterval(setInterval())
            // clearInterval(qq);
            if (params.name == ydata[0].name) {
              myChart.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: 0,
              });
            } else {
              myChart.dispatchAction({
                type: "downplay",
                seriesIndex: 0,
                dataIndex: 0,
              });
            }
          });

          myChart.on("mouseout", function (params) {
            // setInterval(qq)
            myChart.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: 0,
            });
          });
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: 0,
          });
        }, 3000);

        myChart.currentIndex = -1;

        let qq = setInterval(function () {
          let dataLen = option.series[0].data.length;
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: myChart.currentIndex,
          });
          myChart.currentIndex = (myChart.currentIndex + 1) % dataLen;
          // 高亮当前图形
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: myChart.currentIndex,
          });
        }, 3000);

        // 使用刚指定的配置项和数据显示图表。
        /*myChart.setOption(option);*/
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
  },
  mounted() {
    //this.yiqing();
    // this.drawChart();
  },
};
</script>

<style scoped lang="scss">
.idx-right3-box2 {
  width: 100%;
  height: 27vh;
  //   border: 1px solid tan;
  overflow: hidden;
  margin-top: 0vh;
  // border: 1px solid tan;
  .idx-Rbox-1 {
    font-size: 0.9vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #04f3ee;
    padding-left: 1.3vw;
    padding-top: 1vh;
    a {
      color: #04f3ee;
    }
  }
  .idx-Rbox-2 {
    width: 77%;
    height: 4vh;
    line-height: 3vh;
    // border: 1px solid tan;
    font-size: 0.9vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    margin: 0 auto;
    margin-top: 3vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .idx-Rbox-2a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .idx-rs-1 {
        font-size: 1.6vw;
        font-family: hy;
        font-weight: bold;
        font-style: italic;
        color: #ffd901;
      }
      .idx-rs-2 {
        font-size: 0.8vw;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #eeab1a;
      }
    }
  }
  .idx-Rbox-3 {
    height: 21vh;
    padding: 0vh 1vw;
    margin-top: 0vh;
    z-index: 99999;
  }
}
</style>
