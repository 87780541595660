<template>
  <div class="shequFw_content">
    <div class="shequFw_content_item">
        <div class="top">
            <span class="t_l">{{getdatavalue1(60)}}</span>
            <span class="t_r">人</span>
        </div>
        <div class="bottom">
            <span class="t_r">街道驻社区团队</span>
        </div>
    </div>
    <div class="shequFw_content_item">
        <div class="top">
            <span class="t_l">{{getdatavalue1(321)}}</span>
            <span class="t_r">个</span>
        </div>
        <div class="bottom">
            <span class="t_r">党群服务中心</span>
        </div>
    </div>
    <div class="shequFw_content_item">
        <div class="top">
            <span class="t_l">{{getdatavalue1(322)}}</span>
            <span class="t_r">个</span>
        </div>
        <div class="bottom">
            <span class="t_r">党建阵地</span>
        </div>
    </div>
    <div class="shequFw_content_item">
        <div class="top">
            <span class="t_l">{{getdatavalue(128)}}</span>
            <span class="t_r">项</span>
        </div>
        <div class="bottom">
            <span class="t_r">窗口政务服务事项</span>
        </div>
    </div>
    <div class="shequFw_content_item">
        <div class="top">
            <span class="t_l">{{getdatavalue1(62)}}</span>
            <span class="t_r">位</span>
        </div>
        <div class="bottom">
            <span class="t_r">在册志愿者</span>
        </div>
    </div>
    <div class="shequFw_content_item">
        <div class="top">
            <span class="t_l">{{getdatavalue1(323)}}</span>
            <span class="t_r">个</span>
        </div>
        <div class="bottom">
            <span class="t_r">社会组织</span>
        </div>
    </div>
    <div class="shequFw_content_item">
        <div class="top">
            <span class="t_l">{{getdatavalue1(324)}}</span>
            <span class="t_r">项</span>
        </div>
        <div class="bottom">
            <span class="t_r">自助服务事项</span>
        </div>
    </div>
    <div class="shequFw_content_item">
        <div class="top">
            <span class="t_l">{{getdatavalue1(325)}}</span>
            <span class="t_r">个</span>
        </div>
        <div class="bottom">
            <span class="t_r">成立业委会的住宅小区</span>
        </div>
    </div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  data() {
    return {
      dataOptions: [],
      dataOptions1: [],
      queryParams: {
        dataModule: "sqdw",
        dataTyp: "sqdw_sqfwgl",
      },
      queryParams1: {
        dataModule: "sysj",
        dataTyp: "sysj-sqdwgk",
      },
    };
  },
  components: {},
  computed: {},
  created() {
    listData(this.queryParams).then((response) => {
        // console.log("社区服不管理",response);
      this.dataOptions = response.rows;
    });
    listData(this.queryParams1).then((response) => {
        // console.log("首页",response);
      this.dataOptions1 = response.rows;
    });
  },

  methods: {
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
    getdatavalue1(id) {
      return getvalue(this.dataOptions1, id);
    },
  },
};
</script>

<style scoped lang="scss">
.shequFw_content{
    width: 100%;
    margin: 0 auto;
    // background: #eee;
    display: flex;
    flex-wrap: wrap;
    .shequFw_content_item{
        width: 25%;
        height: 8vh;
        // border: 1px solid red;
        padding: 1vh 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .t_r{
            color: #EEAB1A;
            font-size: 0.85vw;
        }
        .top{
            .t_l{
                font-family: hy;
                color: #ffd901;
                font-weight: bold;
                font-style: italic;
                font-size: 1.1vw;
                padding-right: 0.6vw;
            }
        }
        .bottom{
            margin-top: .8vh;
        }
    }
}
</style>
