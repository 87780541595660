<template>
  <div class="idx-left-box3">
    <div class="idx-Lbox-1">安全防控及康体设施</div>
    <div class="idx-Lbox-2" @click="go">
      <div class="idx-Lbox-2-1">
        <div class="b-left" id="anbao"></div>
        <div class="b-cent" id="work"></div>
        <div class="b-right" id="baojie"></div>
      </div>
      <div class="idx-Lbox-2-2">
        <div class="idx-ktss-box">
          <div class="idx-ylleft">
            <div class="idx-ylleft-1">
              <span>会所</span>
              <span>{{ getdatavalue(50) }}</span>
            </div>
            <div class="idx-ylleft-1">
              <span>广场</span>
              <span>{{ getdatavalue(51) }}</span>
            </div>

            <div class="idx-ylleft-1">
              <span>人工湖</span>
              <span>{{ getdatavalue(52) }}</span>
            </div>
            <div class="idx-ylleft-1">
              <span>游泳池</span>
              <span>{{ getdatavalue(53) }}</span>
            </div>
            <div class="idx-ylleft-1">
              <span>篮球场</span>
              <span>{{ getdatavalue(54) }}</span>
            </div>
            <div class="idx-ylleft-1">
              <span>儿童乐园</span>
              <span>{{ getdatavalue(55) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="idx-Lbox-2-3">
        <div class="idx-Lbox-2-3-1">
          <span>治安岗亭</span>
          <span>{{ getdatavalue(56) }}</span>
        </div>
        <div class="idx-Lbox-2-3-1">
          <span>消火栓</span>
          <span>{{ getdatavalue(57) }}</span>
        </div>
        <div class="idx-Lbox-2-3-1">
          <span>监控室</span>
          <span>{{ getdatavalue(58) }}</span>
        </div>
        <div class="idx-Lbox-2-3-1 color_F56A02" @click.stop="dialogVisiblebtn($event)">
          <span>监控头</span>
          <span>{{ getdatavalue(59) }}</span>
        </div>
        <div class="idx-Lbox-2-3-1 idx-Lbox-2-3-last">
          <span>停车位</span>
          <span>{{ getdatavalue(320) }}</span>
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="70%"
      :append-to-body="true"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="dialog-bg">
        <img class="back-ground-image" src="@/assets/image/idx-tk.png" alt="" />
      </div>
      <!--  -->
      <div class="dialog-box">
        <div class="dialog-box-top">监控展示</div>
        <div class="dialog-box-bottom">
          <div class="video-iframe">
            <iframe :src="videoSrc" frameborder="0" height="100%" width="100%"></iframe>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  data() {
    return {
      dataOptions: [],
      props: ["dialog_click"],
      queryParams: {
        dataModule: "sysj",
        dataTyp: "sysj-fkjktsssj",
      },
      //弹框
      dialogVisible: false,
      videoSrc: "/video/mydq/index.html",
    };
  },
  created() {
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
      this.anbaoData();
      this.workData();
      this.baojieData();
    });
  },
  methods: {
    go() {
      let arr = [
        { dataId: '47',label: "安保人员",dataDetailed: this.getdatavalue(47) },
        { dataId: '48',label: "管家",dataDetailed: this.getdatavalue(48) },
        { dataId: '49',label: "保洁人员",dataDetailed: this.getdatavalue(49) },
        { dataId: '50',label: "会所",dataDetailed: this.getdatavalue(50) },
        { dataId: '51',label: "广场",dataDetailed: this.getdatavalue(51) },
        { dataId: '52',label: "人工湖",dataDetailed: this.getdatavalue(52) },
        { dataId: '53',label: "游泳池",dataDetailed: this.getdatavalue(53) },
        { dataId: '54',label: "篮球场",dataDetailed: this.getdatavalue(54) },
        { dataId: '55',label: "儿童乐园",dataDetailed: this.getdatavalue(55) },
        { dataId: '56',label: "治安岗亭",dataDetailed: this.getdatavalue(56) },
        { dataId: '57',label: "消火栓",dataDetailed: this.getdatavalue(57) },
        { dataId: '58',label: "监控室",dataDetailed: this.getdatavalue(58) },
        { dataId: '59',label: "监控头",dataDetailed: this.getdatavalue(59) },
        { dataId: '320',label: "停车位",dataDetailed: this.getdatavalue(320) },
      ]
      this.$emit("dialog_click","安全防控及康体设施",arr);
    },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
    // 安全防控——————————————————————————
    // 安保人员
    anbaoData() {
      // 判断pc iPad
      if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        let myChart = this.$echarts.init(document.getElementById("anbao"));
        /**
         * 图标所需数据
         */
        let data = {
          value: 20.2,
          text: "-",
          color: "#36DD69",
          xAxis: ["安保人员"],
          values: [this.getdatavalue(47)],
        };

        let seriesData = [];
        let titleData = [];
        data.values.forEach(function (item, index) {
          titleData.push({
            text: data.xAxis[index],
            left: 48 * (index + 1) - 0.5 + "%",
            top: "62%",

            textAlign: "center",
            textStyle: {
              fontSize: "10",
              color: "#ffffff",
              fontWeight: "400",
            },
          });
          seriesData.push({
            type: "pie",
            radius: ["40%", "55%"],

            center: [50 * (index + 1) + "%", "35%"],
            hoverAnimation: false,
            label: {
              normal: {
                position: "center",
              },
            },
            data: [
              {
                value: item,
                name: data.text,
                itemStyle: {
                  normal: {
                    color: data.color,
                  },
                },
                label: {
                  normal: {
                    show: false,
                  },
                },
              },
              {
                value: 1000 - item,
                name: "占位",
                tooltip: {
                  show: false,
                },
                itemStyle: {
                  normal: {
                    color: "#123D9C",
                  },
                },
                label: {
                  normal: {
                    formatter: item + "",
                    textStyle: {
                      fontSize: 12,
                      color: data.color,
                    },
                  },
                },
              },
            ],
          });
        });

        ////////////////////////////////////////

        let value = data.value || 0;
        let option = {
          /*backgroundColor: '#fff',*/
          title: titleData,
          series: seriesData,
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      } else {
        let myChart = this.$echarts.init(document.getElementById("anbao"));
        /**
         * 图标所需数据
         */
        let data = {
          value: 20.2,
          text: "-",
          color: "#36DD69",
          xAxis: ["安保人员"],
          values: [this.getdatavalue(47)],
        };

        let seriesData = [];
        let titleData = [];
        data.values.forEach(function (item, index) {
          titleData.push({
            text: data.xAxis[index],
            left: 48 * (index + 1) - 0.5 + "%",
            top: "62%",

            textAlign: "center",
            textStyle: {
              fontSize: "14",
              color: "#ffffff",
              fontWeight: "400",
            },
          });
          seriesData.push({
            type: "pie",
            radius: ["40%", "55%"],

            center: [50 * (index + 1) + "%", "35%"],
            hoverAnimation: false,
            label: {
              normal: {
                position: "center",
              },
            },
            data: [
              {
                value: item,
                name: data.text,
                itemStyle: {
                  normal: {
                    color: data.color,
                  },
                },
                label: {
                  normal: {
                    show: false,
                  },
                },
              },
              {
                value: 1000 - item,
                name: "占位",
                tooltip: {
                  show: false,
                },
                itemStyle: {
                  normal: {
                    color: "#123D9C",
                  },
                },
                label: {
                  normal: {
                    formatter: item + "",
                    textStyle: {
                      fontSize: 14,
                      color: data.color,
                    },
                  },
                },
              },
            ],
          });
        });

        ////////////////////////////////////////

        let value = data.value || 0;
        let option = {
          /*backgroundColor: '#fff',*/
          title: titleData,
          series: seriesData,
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
    // 管家
    workData() {
      // 判断pc iPad
      if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        let myChart = this.$echarts.init(document.getElementById("work"));
        /**
         * 图标所需数据
         */
        let data = {
          value: 20.2,
          text: "-",
          color: "#55ECF3",
          xAxis: ["管家"],
          values: [this.getdatavalue(48)],
        };

        let seriesData = [];
        let titleData = [];
        data.values.forEach(function (item, index) {
          titleData.push({
            text: data.xAxis[index],
            left: 48 * (index + 1) - 0.5 + "%",
            top: "62%",

            textAlign: "center",
            textStyle: {
              fontSize: "10",
              color: "#ffffff",
              fontWeight: "400",
            },
          });
          seriesData.push({
            type: "pie",
            radius: ["40%", "55%"],
            center: [50 * (index + 1) + "%", "35%"],
            hoverAnimation: false,
            label: {
              normal: {
                position: "center",
              },
            },
            data: [
              {
                value: item,
                name: data.text,
                itemStyle: {
                  normal: {
                    color: data.color,
                  },
                },
                label: {
                  normal: {
                    show: false,
                  },
                },
              },
              {
                value: 1000 - item,
                name: "占位",
                tooltip: {
                  show: false,
                },
                itemStyle: {
                  normal: {
                    color: "#123D9C",
                  },
                },
                label: {
                  normal: {
                    formatter: item + "",
                    textStyle: {
                      fontSize: 12,
                      color: data.color,
                    },
                  },
                },
              },
            ],
          });
        });

        ////////////////////////////////////////

        let value = data.value || 0;
        let option = {
          /*backgroundColor: '#fff',*/
          title: titleData,
          series: seriesData,
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      } else {
        let myChart = this.$echarts.init(document.getElementById("work"));
        /**
         * 图标所需数据
         */
        let data = {
          value: 20.2,
          text: "-",
          color: "#55ECF3",
          xAxis: ["管家"],
          values: [this.getdatavalue(48)],
        };

        let seriesData = [];
        let titleData = [];
        data.values.forEach(function (item, index) {
          titleData.push({
            text: data.xAxis[index],
            left: 48 * (index + 1) - 0.5 + "%",
            top: "62%",

            textAlign: "center",
            textStyle: {
              fontSize: "14",
              color: "#ffffff",
              fontWeight: "400",
            },
          });
          seriesData.push({
            type: "pie",
            radius: ["40%", "55%"],
            center: [50 * (index + 1) + "%", "35%"],
            hoverAnimation: false,
            label: {
              normal: {
                position: "center",
              },
            },
            data: [
              {
                value: item,
                name: data.text,
                itemStyle: {
                  normal: {
                    color: data.color,
                  },
                },
                label: {
                  normal: {
                    show: false,
                  },
                },
              },
              {
                value: 1000 - item,
                name: "占位",
                tooltip: {
                  show: false,
                },
                itemStyle: {
                  normal: {
                    color: "#123D9C",
                  },
                },
                label: {
                  normal: {
                    formatter: item + "",
                    textStyle: {
                      fontSize: 14,
                      color: data.color,
                    },
                  },
                },
              },
            ],
          });
        });

        ////////////////////////////////////////

        let value = data.value || 0;
        let option = {
          /*backgroundColor: '#fff',*/
          title: titleData,
          series: seriesData,
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
    // 保洁人员
    baojieData() {
      // 判断pc iPad
      if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        let myChart = this.$echarts.init(document.getElementById("baojie"));
        /**
         * 图标所需数据
         */
        let data = {
          value: 20.2,
          text: "-",
          color: "#F5893D",
          xAxis: ["保洁人员"],
          values: [this.getdatavalue(49)],
        };

        let seriesData = [];
        let titleData = [];
        data.values.forEach(function (item, index) {
          titleData.push({
            text: data.xAxis[index],
            left: 48 * (index + 1) - 0.5 + "%",
            top: "62%",

            textAlign: "center",
            textStyle: {
              fontSize: "10",
              color: "#ffffff",
              fontWeight: "400",
            },
          });
          seriesData.push({
            type: "pie",
            radius: ["40%", "55%"],
            center: [50 * (index + 1) + "%", "35%"],
            hoverAnimation: false,
            label: {
              normal: {
                position: "center",
              },
            },
            data: [
              {
                value: item,
                name: data.text,
                itemStyle: {
                  normal: {
                    color: data.color,
                  },
                },
                label: {
                  normal: {
                    show: false,
                  },
                },
              },
              {
                value: 1000 - item,
                name: "占位",
                tooltip: {
                  show: false,
                },
                itemStyle: {
                  normal: {
                    color: "#123D9C",
                  },
                },
                label: {
                  normal: {
                    formatter: item + "",
                    textStyle: {
                      fontSize: 12,
                      color: data.color,
                    },
                  },
                },
              },
            ],
          });
        });

        ////////////////////////////////////////

        let value = data.value || 0;
        let option = {
          /*backgroundColor: '#fff',*/
          title: titleData,
          series: seriesData,
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      } else {
        let myChart = this.$echarts.init(document.getElementById("baojie"));
        /**
         * 图标所需数据
         */
        let data = {
          value: 20.2,
          text: "-",
          color: "#F5893D",
          xAxis: ["保洁人员"],
          values: [this.getdatavalue(49)],
        };

        let seriesData = [];
        let titleData = [];
        data.values.forEach(function (item, index) {
          titleData.push({
            text: data.xAxis[index],
            left: 48 * (index + 1) - 0.5 + "%",
            top: "62%",

            textAlign: "center",
            textStyle: {
              fontSize: "14",
              color: "#ffffff",
              fontWeight: "400",
            },
          });
          seriesData.push({
            type: "pie",
            radius: ["40%", "55%"],
            center: [50 * (index + 1) + "%", "35%"],
            hoverAnimation: false,
            label: {
              normal: {
                position: "center",
              },
            },
            data: [
              {
                value: item,
                name: data.text,
                itemStyle: {
                  normal: {
                    color: data.color,
                  },
                },
                label: {
                  normal: {
                    show: false,
                  },
                },
              },
              {
                value: 1000 - item,
                name: "占位",
                tooltip: {
                  show: false,
                },
                itemStyle: {
                  normal: {
                    color: "#123D9C",
                  },
                },
                label: {
                  normal: {
                    formatter: item + "",
                    textStyle: {
                      fontSize: 14,
                      color: data.color,
                    },
                  },
                },
              },
            ],
          });
        });

        ////////////////////////////////////////

        let value = data.value || 0;
        let option = {
          /*backgroundColor: '#fff',*/
          title: titleData,
          series: seriesData,
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
    // 监控
    // handleLookVideo(video) {
    //   if (video) {
    //     this.videoSrc = "/video/" + video + "/index.html?name=ylq";
    //     this.dialogVideoVisible = true;
    //   }
    // },
    // xfcxChange(item) {
    //   this.videoSrc =
    //     "/video/" + this.sdzdDefault.video + "/index.html?name=" + item.value;
    // },
    // 详情弹框
    dialogVisiblebtn(e) {
      this.dialogVisible = true;
      console.log("打印eeeeeeeeeeee");
    },
    handleClose(done) {
      done();
      this.dialogVisible = false;
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">

.idx-left-box3 {
  width: 100%;
  height: 33vh;
  // border: 1px solid tan;
  .idx-Lbox-1 {
    font-size: 0.95vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #04f3ee;
    padding-left: 2.5vw;
    padding-top: 2vh;
    margin-bottom: 1vh;
  }
  .idx-Lbox-2 {
    width: 86%;
    height: 30vh;
    // line-height: 4vh;
    // border: 1px solid tan;
    font-size: 0.9vw;
    font-family: Microsoft YaHei;
    margin: 0 auto;
    .idx-Lbox-2-1 {
      width: 100%;
      height: 10vh;
      // border: 1px solid tomato;
      #anbao {
        float: left;
        width: 33.3%;
        height: 10vh;
        margin-top: 0.9vh;
      }
      #work {
        float: left;
        width: 33.3%;
        height: 10vh;
        margin-top: 0.9vh;
      }
      #baojie {
        float: left;
        width: 33.3%;
        height: 10vh;
        margin-top: 0.9vh;
      }
    }
    .idx-Lbox-2-2 {
      width: 100%;
      height: 7vh;
      margin-top: 2vh;
      .idx-ktss-box .idx-ylleft {
        width: 100%;
        height: 6vh;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 0.5px solid #5770ec;
        display: inherit;
        /* margin-left: 2vw; */
        margin-bottom: 1.8vh;
        border-bottom: none;
        border-right: none;
      }

      .idx-ktss-box .idx-ylleft-1 {
        width: 33.3%;
        height: 3vh;
        line-height: 3vh;
        /* border: .1vw solid tan; */
        border-bottom: 0.5px solid #5770ec;
        border-right: 0.5px solid #5770ec;
        float: left;
      }

      .idx-ktss-box .idx-ylleft-1 span {
        padding-left: 0.4vw !important;
        color: #fff;
        font-size: 0.8vw;
      }

      .idx-ktss-box .idx-ylleft-1 span:nth-child(2) {
        float: right;
        padding-right: 0.5vw;
        color: #fff;
        /* font-weight  : bold; */
        padding-top: 0.2vh;
      }
    }
    .idx-Lbox-2-3 {
      width: 100%;
      height: 7vh;
      // border: 1px solid teal;
      margin-top: 2vh;
      .idx-Lbox-2-3-1 {
        width: 16%;
        height: 5.7vh;
        float: left;
        background: linear-gradient(183deg, #145dca, #1634c0);
        border-radius: 0.2vw;
        /* margin: 0 2.6667vw; */
        margin-left: 0vw;
        margin-right: 1.1vw;
        cursor: pointer;
        position: relative;
        span {
          color: #fff;
          width: 100%;
          display: inline-block;
          padding-top: 0.65vh;
          text-align: center;
          &:first-child {
            margin-top: 0vh;
            font-size: 1.4vh;
          }
          &:last-child {
            color: #00a8fe;
            font-size: 1.6vh;
            font-weight: bold;
            position: absolute;
            left: 0;
            top: 2.5vh;
          }
        }
        // span:first-child {
        //   margin-top: 0vh;
        //   font-size: 1.4vh;
        // }
        // span:last-child {
        //   color: #00a8fe;
        //   font-size: 1.6vh;
        //   font-weight: bold;
        //   position: absolute;
        //   left: 0;
        //   top: 2.5vh;
        // }
      }
      .color_F56A02{
        background: linear-gradient(0deg, #F56A02, #FBA15E);
        span{
          &:last-child {
            color: #fff;
          }
        }
      }
      .idx-Lbox-2-3-last {
        margin-right: 0vw;
      }
    }
  }
}
::v-deep .el-dialog {
  position: relative;
  margin: 0 auto 50px;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 99999;
  width: 50%;
  height: 66vh;
  // background: url();
  background: rgba(255, 255, 255, 0);
  // border: 1px solid teal !important;
  .el-dialog__header {
    /* padding: 20px 20px 10px; */
    padding-top: 4vh;
  }
  .el-dialog__body {
    padding: 0.2vw 0.2vh;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
  .el-dialog__headerbtn {
    width: 4vw;
    height: 5vh;
    // border: 1px solid teal !important;
    position: absolute;
    top: 5.5vh;
    right: -0.4vw;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    opacity: 0;
    cursor: pointer;
    font-size: 16px;
    z-index: 999999;
  }
  .back-ground-image {
    width: 100%;
    height: 60vh;
    // border: 1px solid tan;
  }
  .dialog-box {
    position: absolute;
    top: 6.5vh;
    right: 3.5vw;
    width: 91%;
    height: 59vh;
    overflow: hidden;
    // border: 1px solid thistle;
    .dialog-box-top {
      width: 100%;
      height: 6vh;
      line-height: 6vh;
      text-align: center;
      // border: 1px solid thistle;
      font-size: 0.9vw;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffd901;
    }
    .dialog-box-bottom {
      width: 100%;
      height: 50vh;
      // border: 1px solid tan;
      margin-top: 1vh;
      .video-iframe {
        width: 100%;
        height: 49vh;
        // border: 1px solid tan;
      }
    }
  }
}
</style>
