<template>
  <div class="index-map-view">
    <bm-map
      :center="center"
      :markers="markers"
      :areaMarkers="areaMarkers"
      :marker-type="markerType"
      :marker-click="markerClick"
      :zoom="zoom"
      :refresh="refresh"
    ></bm-map>
    <div class="map-btn-view">
      <div
        class="btn-view"
        @click="updateMarkers(item.dictValue)"
        v-for="item in data"
        :key="item.dictCode"
      >
        <img :src="getImg(item.dictValue)" alt="" class="btn-icon" />
        <span>{{ item.dictLabel }}</span>
      </div>
    </div>

    <!-- 弹框 -->
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="70%"
      :append-to-body="true"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="dialog-bg">
        <img class="back-ground-image" src="@/assets/image/idx-tk.png" alt="" />
      </div>
      <!--  -->
      <div class="dialog-box">
        <div class="dialog-box-top" @click="handleLookVideo(sdzdDefault.video)">
          {{ sdzdDefault.title }}
        </div>
        <div class="dialog-box-bottom">
          <div class="dialog-box-bottom-L">
            {{ sdzdDefault.content }}
          </div>
          <div class="dialog-box-bottom-R">
            <div class="dialog-box-img">
              <img
                class="back-ground-image2"
                v-for="(item, index) in sdzdDefault.images"
                :key="index"
                :src="getImageUrl(item)"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title=""
      :visible.sync="dialogVideoVisible"
      width="70%"
      :append-to-body="true"
      :before-close="handleVideoClose"
    >
      <div class="dialog-bg">
        <img class="back-ground-image" src="@/assets/image/idx-tk.png" alt="" />
      </div>
      <!-- 党区服务中心弹出框 -->
      <div class="dialog-box">
        <div class="dialog-box-top">{{ sdzdDefault.title }}</div>
        <div class="dialog-box-bottom">
          <div class="video-iframe" :class="'video-iframe-' + sdzdDefault.video">
            <iframe :src="videoSrc" frameborder="0" height="100%" width="100%"></iframe>
          </div>
          <div class="video-select" :class="'video-select-' + sdzdDefault.video">
            <el-select v-model="xfcxSelected" placeholder="请选择" @change="xfcxChange">
              <el-option
                v-for="item in xfcxSelectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BmMap from "@/components/bmMap";
import { listMap } from "@/api/map";
import { getDicts } from "@/api/data";

export default {
  name: "indexMap",
  components: {
    BmMap,
  },
  data() {
    return {
      center: { lng: 113.774172, lat: 22.999 },
      zoom: 14,
      refresh: 0,
      markers: [],
      areaMarkers: [],
      data: [],
      span: 20,
      sdzdSignsData: {
        mydq: {
          title: "",
          content:
            "民盈国贸党群服务中心位于民盈国贸中心T1办公楼36楼，占地面积180平方米，配置一名党建组织员、两名党务社工。中心设置了5个功能区：引导咨询区，安排有关职能部门人员和党员志愿者以轮值的形式，为党员群众提供前台咨询、政策宣讲、专业引导、办事材料收件等服务。自助服务区，配置自助办证、生活缴费等服务的出入境签注终端设备1台和市政一体化政务服务终端设备1台，全天候为党员群众提供党务政务服务。党群活动区，设置主题突出，美观实用，功能多样的活动区，配备多媒体电教设备。为党员群众提供政策宣讲、教育培训、文体活动等服务。创客交流区，设置休息或讨论式桌椅、书籍、书架等设施，为商户提供一个线下交流沟通的平台。党建展示区，集中展示东城街道及中心党建的总体情况及最新城市基层党建成果，展现街道“两新”党员群众“团结、奉献、创新、发展”的精神面貌。",
          images: [
            "idx-my-1.jpg",
            "idx-my-2.jpg",
            "idx-my-3.jpg",
            "idx-my-4.jpg",
            "idx-my-5.jpg",
            "idx-my-6.jpg",
          ],
          video: "mydq",
        },
        zhwy: {
          title: "",
          content:
            "中海物业社区老年大学成立于2005年6月17日，办学宗旨是“老有所乐、老有所学、老有所为”，办学性质为公益性，是东莞市首创的社区老年大学。中海物业社区老年大学已成功在中海物业各社区开办五所分校，分别是:2008年8月30日新天地分校成立、2012年9月5日凯旋国际分校成立、2014年12月观澜凯旋城分校成立、2015年9月10日森林湖分校成立、2018年9月5日富春山居分校成立，总校加分校六大校区共有学员逾1200名。另外，2017年6月，老年大学临时党支部成立，为老年大学的流动党员提供了学习生活的组织平台，设立党员活动室、阅览室，目前在册的党员54人，流动党员10，共64名。 在社区居民委员会和东城区府的关怀下，老年大学的教学条件也得到日益改善，目前老年大学已配备了授课室、书画室、图书室等，为学员创造了一个良好的学习环境。常年免费开设有5班2队1团(太极班、舞蹈班、音乐班、知识班、书画班、民乐队、门球队、合唱团)，同时积极开展户外教育游览活动，课程丰富多样，深受社区老年朋友的欢迎。社区老年大学的成功开办，已成为中海物业社区乃至东莞市全面推进平安和谐社区建设的一面鲜红旗帜!",
          images: ["idx-ln-1.jpg", "idx-ln-2.png", "idx-ln-3.jpg"],
          video: false,
        },
        xfcx: {
          title: "",
          content:
            "为打通服务联系群众最后“一百米”，提升居民的获得感和幸福感，2019年12月，我社区党工委与光大集团在景湖春晓住宅小区合力打造综合性服务阵地“幸福春晓驿站”（约1000平方米）。幸福春晓驿站”以群众多元化需求为导向，整合社区、社会组织、小区物业等多元主体力量，设立了党建功能室、图书驿站、430课堂、舞蹈室、心理辅导室等多功能活动室，因地制宜并结合小区居民需求，开展系列丰富多彩、康乐共融的便民活动，积极服务小区党员群众，推动小区党员群众的互联互动，为社区党员群众营造一个“阳光、睦邻、康乐、友善”的社区生活新模式。幸福春晓驿站”的建成，是“双标工程”示范点建设的重要一环，是深化拓展基层党建三年行动计划的有力举措，更是巩固深化“不忘初心、牢记使命”主题教育的重要体现。这既是对上级党组织关于强化党建引领基层治理的积极回应，也是进一步完善“党建+服务”模式的有效创新。它是基层党组织在住宅小区这一新兴领域的有效形成与覆盖，对于探索城市基层治理、推进党建结对共建起到重要作用。下来，东泰社区党工委将继续充分发挥春晓驿站的阵地作用，整合各种优势资源，实现区域内“组织共建、资源共享”；定期开展党员教育、展示、交流、服务、关爱等活动，提升春晓驿站的服务水平和组织活动的效果，将其打造成为党建的亮点；真正把春晓驿站用起来，成为服务党员、服务群众、服务社会的红色堡垒和温馨家园。",
          images: [
            "idx-xf-1.jpg",
            "idx-xf-2.jpg",
            "idx-xf-3.jpg",
            "idx-xf-4.jpg",
            "idx-xf-5.jpg",
            "idx-xf-6.jpg",
          ],
          video: "xfcx",
        },
        wkzt: {
          title: "",
          content:
            "万科中天城市花园距离社区党群服务中心4.7公里，是目前位置距离社区党群服务中心最远的居民小区，步行时间大约需要58分钟，这导致中心公共服务很难覆盖到该小区。为更好地为辖区党员群众服务，营造便民利民环境，拓宽服务功能，东泰社区党工委在群众最需要的地方——万科中天城市花园建立党群服务站。通过将服务站打造成党员活动、服务群众、协商议事的“三合一”平台，以其作为社区党工委、居委会、社区党群服务中心的延伸，从而将党建阵地和公共服务推进到广大党员群众的家门口，避免党员群众多跑腿，以实际行动增强群众的获得感。作为社区党群服务中心的延伸阵地，中心的计生、民政、社保等业务办理及工作人员、工作力量将延伸到党群服务站，党员群众可以就近在这里办理居住登记、生育登记、民政各类救济申请、城乡居民医保购买等公共行政事务；同时，借助物业公司、共建单位力量，为党员群众提供更加丰富、快捷的信息咨询、办事指引、文明宣传、志愿服务登记等基础服务，进一步提升党员群众的办事环境和社区服务质量，下一步，东泰社区党工委将继续“不忘初心、牢记使命”，充分利用新的服务阵地，打通服务联系群众最后一公里，积极推进党群共融，实现办公有场所、活动有场地、学习有去处，为辖区党员群众打造一个多元化的社区活动阵地，更好地为辖区党员群众服务提供效益更好、内容更全、层次更高的服务，实现为民服务效能最大化。",
          images: ["idx-wk-1.jpg", "idx-wk-2.jpg", "idx-wk-3.jpg"],
          video: false,
        },
      },
      sdzdDefault: { title: "", content: "", video: false, images: [] },
      xfcxSelectOptions: [
        { value: "ylq", label: "阅览区" },
        { value: "qt", label: "前厅" },
        { value: "djs", label: "党建室" },
        { value: "gns", label: "功能室" },
      ],
      xfcxSelected: "ylq",
      markerType: "xqzb",
      // markerType: "dizhi",
      videoSrc: "http://dt.baikegz.com:3083/video/xfcx/index.html?name=ylq",
      //弹框
      dialogVisible: false,
      dialogVideoVisible: false,
    };
  },
  created() {
    this.create();
    this.updateMarkers1(this.markerType);
    // this.updateMarkers(this.markerType);
  },
  methods: {
    updateMarkers(type) {
      console.log("typeupdateMarkers", type);
      this.markerType = type;
      listMap({ mapTyp: type }).then((res) => {
        console.log(res);
        if (type == "xqzb") {
          this.areaMarkers = [];
        } else {

          if (this.areaMarkers.length == 0) {
            this.areaMarkers = this.markers.concat([]);
          }
        }
        this.markers = this.dealMarker(res.rows);
        this.refresh++;
      });
    },
    updateMarkers1(type) {
      listMap({ mapTyp: type }).then((res) => {
        console.log("地图：",res);
        this.markers = this.dealMarker(res.rows);
        this.refresh++;
      });
    },

    // 小区
    xiaoqumap() {
      listMap({ mapTyp: "xqzb" }).then((res) => {
        this.markers = [];
        this.markers2 = this.dealMarker(res.rows);
        this.refresh++;
      });
    },
    create() {
      getDicts("map_point").then((res) => {
        console.log(res);
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].remark) {
            console.log(JSON.parse(res.data[i].remark));
            let center = JSON.parse(res.data[i].remark);
            res.data[i]["center"] = center;
            res.data[i]["zoom"] = center.zoom;
          }
        }
        this.data = res.data;
      });
    },
    getImg(type) {
      return require("@/assets/icon/" + type + ".png");
    },
    markerClick(index) {
      // let marker = this.markers[index];
      console.log(this.markers);
      let marker = this.markers[parseInt(index.toString())];
      console.log(this.markerType);
      if (this.markerType == "xqzb") {
        this.$router.push({
          path: "/xiaoqu",
          query: {
            lng: marker.longitude,
            lat: marker.latitude,
            mapPara: marker.mapPara,
            mapName: marker.mapName,
          },
        });
      }
      // if (this.markerType == "dqfwzx") {
      if (this.markerType == "djzd") {
        console.log(marker);
        console.log("党群服务中心");
        this.sdzdSignsData[marker.mapPara].title = marker.mapName;
        this.sdzdDefault = this.sdzdSignsData[marker.mapPara];
        this.dialogVisible = true;
      }
    },
    // 详情弹框
    // dialogVisiblebtn() {
    //   this.dialogVisible = true;
    // },
    handleClose(done) {
      done();
      this.dialogVisible = false;
    },
    handleVideoClose(done) {
      done();
      this.dialogVideoVisible = false;
    },
    close() {
      this.isShow = false;
    },
    getImageUrl(name) {
      return require("@/assets/image/sdzd/" + name);
    },
    handleLookVideo(video) {
      if (video) {
        this.videoSrc = "/video/" + video + "/index.html?name=ylq";
        this.dialogVideoVisible = true;
      }
    },
    xfcxChange() {
      this.videoSrc =
        "/video/" + this.sdzdDefault.video + "/index.html?name=" + this.xfcxSelected;
    },
    dealMarker(markers) {
      console.log(markers);
      for (let i = 0; i < markers.length; i++) {
        markers[i].lng = markers[i].longitude;
        markers[i].lat = markers[i].latitude;
      }
      return markers;
    },
    dealMarker2(markers2) {
      for (let i = 0; i < markers2.length; i++) {
        markers2[i].lng = markers2[i].longitude;
        markers2[i].lat = markers2[i].latitude;
      }
      return markers2;
    },
  },
};
</script>

<style scoped lang="scss">
.index-map-view {
  width: 94.5%;
  // height: 30.4vh;
  // height: 42.4vh;
  height: 50.6vh;
  position: relative;
  overflow: hidden;
  margin-left: 1.45vw;
  // border: 1px solid teal;

  #map-echarts-view {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .map-btn-view {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    margin-left: 1.8vw;
    color: #04f3ee;

    .btn-view {
      height: 5.8vh;
      margin-bottom: 0.4vh;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      line-height: 4.1vh;
      padding-left: 1.85vw;
      font-size: 0.85vw;
      position: relative;
      cursor: pointer;
      img {
        position: absolute;
        top: 0.1vh;
        left: -0.2vw;
        width: 2vw;
        height: 2vw;
      }
    }
  }
}
::v-deep .el-dialog {
  position: relative;
  margin: 0 auto 50px;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 99999;
  width: 50%;
  height: 50vh;
  // background: url();
  background: rgba(255, 255, 255, 0);
  .el-dialog__header {
    /* padding: 20px 20px 10px; */
    padding-top: 4vh;
  }
  .el-dialog__body {
    padding: 0.2vw 0.2vh;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
  .el-dialog__headerbtn {
    width: 4vw;
    height: 5vh;
    // border: 1px solid teal !important;
    position: absolute;
    top: 6vh;
    right: 0vw;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
    z-index: 999999;
    opacity: 0;
  }
  .back-ground-image {
    width: 100%;
    height: 60vh;
    // border: 1px solid tan;
  }
  .dialog-box {
    position: absolute;
    top: 8vh;
    right: 3.5vw;
    width: 91%;
    height: 58vh;
    overflow: hidden;
    // border: 1px solid thistle;
    .dialog-box-top {
      width: 100%;
      height: 6vh;
      line-height: 3vh;
      text-align: center;
      // border: 1px solid thistle;
      font-size: 0.9vw;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffd901;
      cursor: pointer;
    }
    .dialog-box-bottom {
      width: 100%;
      height: 48vh;
      overflow: hidden;
      margin-top: 2vh;
      overflow-y: auto;
      .dialog-box-bottom-L {
        width: 40%;
        height: 48vh;
        font-size: 0.8vw;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 3.5vh;
        text-align: justify;
        float: left;
      }
      .dialog-box-bottom-R {
        width: 59%;
        height: 65vh;
        // border: 1px solid tan;
        float: right;
        img {
          width: 44%;
          height: 21vh;
          float: left;
          margin: 0 0 4vh 2vw;
        }
      }
      .video-iframe {
        width: 75%;
        height: 100%;
        float: left;
        height: 40vh;
      }
      .video-iframe-mydq {
        width: 100%;
        height: 48vh;
      }
      .video-select {
        width: 24%;
        float: right;
      }
      .video-select-mydq {
        display: none;
      }
    }
  }
}
</style>
