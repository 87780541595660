<template>
  <div class="idx-middle-box1">
    <!-- <div class="idx-Lbox-1">
      <router-link tag="a" to="/community">社区管理队伍概况</router-link>
    </div> -->
    <div class="idx-Lbox-2">
      <div class="idx-Lbox-2a">
        <div class="idx-Lbox-2a-1">街道驻社区团队（人）</div>
        <div class="idx-Lbox-2a-2">
          <div class="idx-Lbox-2a-box">0000</div>
          <div class="idx-Lbox-2a-box2">{{ getdatavalue(60) }}</div>
        </div>
      </div>
      <div class="idx-Lbox-2b">
        <div class="idx-Lbox-3">
          <div class="idx-Lbox-3L">
            <div class="idx-Lbox-3L-top">养老服务机构</div>
            <div class="idx-Lbox-3L-bt">{{ getdatavalue(61) }}</div>
          </div>
          <div class="idx-Lbox-3R">
            <div class="idx-Lbox-3R-top">志愿者</div>
            <div class="idx-Lbox-3R-bt">{{ getdatavalue(62) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  data() {
    return {
      dataOptions: [],
      queryParams: {
        dataModule: "sysj",
        dataTyp: "sysj-sqdwgk",
      },
    };
  },
  created() {
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
    });
  },
  methods: {
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.idx-middle-box1 {
  width: 100%;
  height: 18vh;
  // border: 1px solid tan;
  .idx-Lbox-1 {
    font-size: 0.9vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #04f3ee;
    padding-left: 2.5vw;
    padding-top: 3vh;
    a {
      color: #04f3ee;
    }
  }
  .idx-Lbox-2 {
    width: 89%;
    height: 8vh;
    line-height: 10vh;
    // border: 1px solid tomato;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;

    .idx-Lbox-2a {
      // font-family: "hy";
      // font-weight: bold;
      // font-style: italic;
      // font-size: 2vw;
      // color: #ffd901;
      // opacity: 0.1;
      // letter-spacing: 1.255vw;
      // height: 7vh;
      // padding-left: 1.5vw;
      // line-height: 7vh;
      width: 100%;
      height: 10vh;
      // border: 1px solid tan;
      float: left;
      position: relative;
      z-index: 0;
      .idx-Lbox-2a-1 {
        width: 100%;
        height: 4vh;
        line-height: 4vh;
        color: #fff;
        font-size: 0.85vw;
        padding-left: 1.7vw;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #04f3ee;
      }
      .idx-Lbox-2a-2 {
        width: 100%;
        height: 6vh;
        // border: 1px solid thistle;
        position: relative;
        .idx-Lbox-2a-box {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          font-family: "hy";
          font-weight: bold;
          font-style: italic;
          font-size: 1.6vw;
          color: #ffd901;
          opacity: 0.1;
          letter-spacing: 1.1vw;
          height: 5vh;
          padding-left: 2.1vw;
          line-height: 8.5vh;
          position: relative;
          z-index: 0;
          span {
            display: inline-block;
            width: 25%;
            height: 4.3vh;
            line-height: 4.3vh;
            text-align: center;
            // background: #0e2a53;
            font-family: "hy";

            font-size: 2vw;
            font-weight: bold;
            font-style: italic;
            color: rgba(255, 217, 1, 0.1);
            // opacity: 0.1;
          }
        }
        .idx-Lbox-2a-box2 {
          position: absolute;
          top: 0.1vh;
          left: -0.7vw;
          font-family: "hy";
          font-style: italic;
          font-weight: bold;
          font-size: 1.6vw;
          color: #ffd901;
          opacity: 1;
          letter-spacing: 1.2vw;
          padding-left: 1.5vw;
          height: 1vh;
          line-height: 5vh;
          text-align: right;
          width: 100%;
          z-index: 999;
          // display: none;
        }
      }
    }
    .idx-Lbox-2b {
      width: 100%;
      height: 10vh;
      // border: 1px solid tan;
      float: left;
      .idx-Lbox-3 {
        width: 100%;
        height: 10vh;
        line-height: 2vh;
        font-size: 2vw;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #04f3ee;
        margin: 0 auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;

        .idx-Lbox-3L {
          margin-top: 1.9vh;
          margin-left: 3vw;
          margin-bottom: 4vh;
          .idx-Lbox-3L-top {
            width: 7vw;
            font-size: 0.85vw;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #04f3ee;
            padding-bottom: 1vh;
          }
          .idx-Lbox-3L-bt {
            font-size: 1.7vw;
            font-family: zcoolqingkehuangyouti;
            font-weight: bold;
            font-style: italic;
            color: #eeab1a;
            font-family: "hy";
          }
        }
        .idx-Lbox-3R {
          margin-left: 3vw;
          .idx-Lbox-3R-top {
            width: 7vw;
            font-size: 0.85vw;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #04f3ee;
            padding-bottom: 1vh;
          }
          .idx-Lbox-3R-bt {
            font-size: 1.7vw;
            font-family: zcoolqingkehuangyouti;
            font-weight: bold;
            font-style: italic;
            color: #eeab1a;
            font-family: "hy";
          }
        }
      }
    }
  }
}
</style>
