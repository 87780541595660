<template>
  <div class="idx-middle-box3">
    <div class="idx-middle-1">最新事件</div>
    <div class="idx-middle-2">
      <!-- 标题 -->
      <div class="idx-middle-title">
        <span class="idx-M-s1">事件</span>
        <span class="idx-M-s2">类型</span>
        <span class="idx-M-s3">时间</span>
      </div>
      <div class="scroll">
        <!-- <div id="scrollDiv" ref="rollul" :class="{ anim: animate == true }">
          <div class="scroll-box" v-for="item in scroll_lists" :key="item.name">
            <span class="idx-M-s1">{{ item.name }}</span>
            <span class="idx-M-s2">{{ item.leixing }}</span>
            <span class="idx-M-s3">{{ item.time }}</span>
          </div>
        </div> -->
        <!--  -->
        <vue-seamless-scroll :data="scroll_lists" id="scrollDiv">
          <div class="scroll-box" v-for="item in scroll_lists" :key="item.dataId">
            <span class="idx-M-s1">{{ item.dataField1 }}</span>
            <span class="idx-M-s2">{{ item.dataField2 }}</span>
            <span class="idx-M-s3">{{ item.dataField3 }}</span>
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  components: {},
  data() {
    return {
      animate: false,
      index: undefined,
      scroll_lists: [],
      dataOptions: [],
      queryParams: {
        dataModule: "sysj_data",
        dataTyp: "sysj-zxsj",
      },
    };
  },
  components: {
    //组件
    vueSeamlessScroll,
  },
  computed: {
    classOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created() {
    // setInterval(this.scroll, 2000);
    // this.scroll();
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
      this.scroll_lists = response.rows;
    });
  },

  methods: {
    // scroll() {
    //   this.animate = true;
    //   let that = this;
    //   setTimeout(function () {
    //     that.scroll_lists.push(that.scroll_lists[0]);
    //     that.scroll_lists.shift();
    //     that.animate = false;
    //   }, 1500);
    // },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
  },
};
</script>

<style scoped lang="scss">
.idx-middle-box3 {
  width: 100%;
  height: 33vh;
  //   border: 1px solid tan;
  .idx-middle-1 {
    font-size: 0.9vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #04f3ee;
    padding-left: 2.5vw;
    padding-top: 2vh;
  }
  .idx-middle-2 {
    width: 95%;
    height: 8vh;
    line-height: 10vh;
    margin: 0 auto;
    position: relative;
    margin-left: 1.35vw;
    .idx-middle-title {
      width: 100%;
      height: 4vh;
      line-height: 4vh;
      background: rgba(25, 55, 155, 0.8);
      margin-top: 2vh;
      span {
        color: #ffffff;
        font-size: 0.8vw;
        display: inline-block;
      }
      .idx-M-s1 {
        width: 50%;
        text-indent: 1.3vw;
      }
      .idx-M-s2 {
        width: 35%;
      }
      .idx-M-s3 {
        width: 15%;
      }
    }
    .scroll {
      #scrollDiv {
        width: 100%;
        height: 25vh;
        // border: 1px solid tan;
        overflow: hidden;
        span {
          color: #ffffff;
        }
        .scroll-box {
          width: 100%;
          width: 100%;
          // height: auto;
          min-height: 6vh;
          max-height: 12vh;
          line-height: 6vh;
          overflow: hidden;
          //   border: 1px solid teal;
          //   &:nth-child(odd) {
          //     background: rgba(28, 108, 195, 0.8);
          //   }
          &:nth-child(even) {
            background: rgba(28, 108, 195, 0.8);
          }
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          span {
            color: #ffffff;
            font-size: 0.8vw;
            line-height: 3vh;
            text-align: justify;
            display: inline-block;
          }
          .idx-M-s1 {
            width: 45%;
            margin: 0 auto;
            padding-left: 1.3vw;
            padding-right: 1vw;
          }
          .idx-M-s2 {
            width: 35%;
          }
          .idx-M-s3 {
            width: 15%;
          }
        }
      }
    }
  }
}
</style>
