<template>
  <div class="idx-right2-box2">
    <div class="idx-Rbox-1">
      <router-link to="/mingshengJq">民生警情</router-link>
    </div>
    <div class="idx-Rbox-2" @click="go">
      <div class="idx-Rbox-2a">
        <span class="idx-rs-1">{{ getdatavalue(73) }}</span>
        <span class="idx-rs-2">全部事件</span>
      </div>
      <div class="idx-Rbox-2a">
        <span class="idx-rs-1">{{ getdatavalue(74) }}</span>
        <span class="idx-rs-2">已处理</span>
      </div>
      <div class="idx-Rbox-2a">
        <span class="idx-rs-1">{{ getdatavalue(75) }}</span>
        <span class="idx-rs-2">处理率</span>
      </div>
    </div>
    <!--民生警情  -->
    <div class="idx-Rbox-3" id="mingsheng"></div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  props: ["dialog_click"],
  data() {
    return {
      ydata: [],
      xdata: [],
      dataOptions: [],
      dataOptions1: [],
      queryParams: {
        dataModule: "sysj",
        dataTyp: "sysj-mshz",
      },
      queryParams1: {
        dataModule: "sysj",
        dataTyp: "sysj_msjqmx",
      },
    };
  },
  created() {
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
    });

    listData(this.queryParams1).then((response) => {
      for (let i = 0; i < response.rows.length; i++) {
        this.ydata[i] = {};
        this.ydata[i].name = response.rows[i].dataName;
        this.ydata[i].value = parseInt(response.rows[i].dataDetailed);
        this.xdata[i] = response.rows[i].dataName;
        // console.log(this.ydata[i].name+':'+this.ydata[i].value);
      }
      this.dataOptions1 = response.rows
      this.mingsheng();
    });
  },
  methods: {
    go() {
      let arr = this.dataOptions.map(item=>{
        return {
          dataDetailed: item.dataDetailed,
          dataId: item.dataId,
          label: item.dataName
        }
      })
      // let arr = [
      //   ...arr1,
      //   { dataId: '73',label: "全部事件",dataDetailed: this.getdatavalue(73) },
      //   { dataId: '74',label: "已处理",dataDetailed: this.getdatavalue(74) },
      //   { dataId: '75',label: "处理率",dataDetailed: this.getdatavalue(75) },
        
      // ]
      this.$emit("dialog_click","民生警情",arr);
    },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
    // 民生警情环形
    mingsheng() {
      // 判断pc iPad
      if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        let myChart = this.$echarts.init(document.getElementById("mingsheng"));
        // let xdata = ["盗窃案件", "诈骗案件", "公民求助"];
        let ydata = this.ydata;
        let color = ["#F56B03", "#24D8D7", "#2363F1"];
        // let color = ["#25F3E6", "#FF7F50", "#DA70D6"]
        // let color = ["#87CEFA", "#FF7F50", "#DA70D6"]
        let xdata = this.xdata;

        let option = {
          /*backgroundColor: "rgba(255,255,255,1)",*/
          title: {
            //   text: "民生警情",
            subtext: "",
            textStyle: {
              color: "#FFFFFF",
              fontSize: 14, //文字的字体大小
            },
            left: "23%",
            top: "85.5%",
          },
          color: color,
          legend: {
            orient: "vertical",
            top: "10%",
            left: "4%",
            bottom: "0%",
            data: xdata,
            itemWidth: 10,
            itemHeight: 10,
            textStyle: {
              //图例文字的样式
              color: "#fff",
              fontSize: 12,
              padding: 3,
            },
            /*itemGap: 14,*/
            /*formatter:function(name){
				  let oa = option.series[0].data;
				  let num = oa[0].value + oa[1].value + oa[2].value + oa[3].value+oa[4].value + oa[5].value + oa[6].value + oa[7].value+oa[8].value + oa[9].value ;
				  for(let i = 0; i < option.series[0].data.length; i++){
				      if(name==oa[i].name){
				          return ' '+name + '    |    ' + oa[i].value + '    |    ' + (oa[i].value/num * 100).toFixed(2) + '%';
				      }
				  }
				}*/

            formatter: function (name) {
              return "" + name;
            },
          },
          series: [
            {
              type: "pie",
              clockwise: false, //饼图的扇区是否是顺时针排布
              minAngle: 2, //最小的扇区角度（0 ~ 360）
              // radius: ["45%", "55%"],
              // center: ["30%", "53%"],
              radius: ["40%", "50%"],
              center: ["80%", "40%"],
              avoidLabelOverlap: false,
              itemStyle: {
                //图形样式
                normal: {
                  // borderColor: "#ffffff",
                  borderWidth: 1,
                },
              },
              label: {
                normal: {
                  show: false,
                  position: "center",
                  formatter: "{text|{b}}\n{c}",
                  // formatter: '{text|{b}}\n {text|{c} ({d}%)}',
                  // formatter: '{text|{b}}\n{c} ({d}%)',
                  rich: {
                    text: {
                      color: "#fff",
                      fontSize: 12,
                      align: "center",
                      verticalAlign: "middle",
                      padding: 8,
                    },
                    value: {
                      color: "#fff",
                      fontSize: 12,
                      align: "center",
                      verticalAlign: "middle",
                    },
                  },
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: 14,
                  },
                },
              },

              data: ydata,
            },
          ],
        };
        myChart.setOption(option);

        setTimeout(function () {
          myChart.on("click", function (params) {
            dzbMap(params.name);
          });
          myChart.on("mouseover", function (params) {
            // window.clearInterval(setInterval())
            // clearInterval(qq);
            if (params.name == ydata[0].name) {
              myChart.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: 0,
              });
            } else {
              myChart.dispatchAction({
                type: "downplay",
                seriesIndex: 0,
                dataIndex: 0,
              });
            }
          });

          myChart.on("mouseout", function (params) {
            // setInterval(qq)
            myChart.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: 0,
            });
          });
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: 0,
          });
        }, 3000);

        myChart.currentIndex = -1;

        let qq = setInterval(function () {
          let dataLen = option.series[0].data.length;
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: myChart.currentIndex,
          });
          myChart.currentIndex = (myChart.currentIndex + 1) % dataLen;
          // 高亮当前图形
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: myChart.currentIndex,
          });
        }, 3000);

        // 使用刚指定的配置项和数据显示图表。
        /*myChart.setOption(option);*/
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      } else {
        let myChart = this.$echarts.init(document.getElementById("mingsheng"));
        // let xdata = ["盗窃案件", "诈骗案件", "公民求助"];
        let ydata = this.ydata;
        let color = ["#F56B03", "#24D8D7", "#2363F1"];
        // let color = ["#25F3E6", "#FF7F50", "#DA70D6"]
        // let color = ["#87CEFA", "#FF7F50", "#DA70D6"]
        let xdata = this.xdata;

        let option = {
          /*backgroundColor: "rgba(255,255,255,1)",*/
          title: {
            //   text: "民生警情",
            subtext: "",
            textStyle: {
              color: "#FFFFFF",
              fontSize: 14, //文字的字体大小
            },
            left: "23%",
            top: "85.5%",
          },
          color: color,
          legend: {
            orient: "vertical",
            top: "10%",
            left: "4%",
            bottom: "0%",
            data: xdata,
            itemWidth: 10,
            itemHeight: 10,
            textStyle: {
              //图例文字的样式
              color: "#fff",
              fontSize: 14,
              padding: 8,
            },
            /*itemGap: 14,*/
            /*formatter:function(name){
				  let oa = option.series[0].data;
				  let num = oa[0].value + oa[1].value + oa[2].value + oa[3].value+oa[4].value + oa[5].value + oa[6].value + oa[7].value+oa[8].value + oa[9].value ;
				  for(let i = 0; i < option.series[0].data.length; i++){
				      if(name==oa[i].name){
				          return ' '+name + '    |    ' + oa[i].value + '    |    ' + (oa[i].value/num * 100).toFixed(2) + '%';
				      }
				  }
				}*/

            formatter: function (name) {
              return "" + name;
            },
          },
          series: [
            {
              type: "pie",
              clockwise: false, //饼图的扇区是否是顺时针排布
              minAngle: 2, //最小的扇区角度（0 ~ 360）
              // radius: ["45%", "55%"],
              // center: ["30%", "53%"],
              radius: ["35%", "55%"],
              center: ["80%", "40%"],
              avoidLabelOverlap: false,
              itemStyle: {
                //图形样式
                normal: {
                  // borderColor: "#ffffff",
                  borderWidth: 1,
                },
              },
              label: {
                normal: {
                  show: false,
                  position: "center",
                  formatter: "{text|{b}}\n{c}",
                  // formatter: '{text|{b}}\n {text|{c} ({d}%)}',
                  // formatter: '{text|{b}}\n{c} ({d}%)',
                  rich: {
                    text: {
                      color: "#fff",
                      fontSize: 14,
                      align: "center",
                      verticalAlign: "middle",
                      padding: 8,
                    },
                    value: {
                      color: "#fff",
                      fontSize: 14,
                      align: "center",
                      verticalAlign: "middle",
                    },
                  },
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: 14,
                  },
                },
              },

              data: ydata,
            },
          ],
        };
        myChart.setOption(option);

        setTimeout(function () {
          myChart.on("click", function (params) {
            dzbMap(params.name);
          });
          myChart.on("mouseover", function (params) {
            // window.clearInterval(setInterval())
            // clearInterval(qq);
            if (params.name == ydata[0].name) {
              myChart.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: 0,
              });
            } else {
              myChart.dispatchAction({
                type: "downplay",
                seriesIndex: 0,
                dataIndex: 0,
              });
            }
          });

          myChart.on("mouseout", function (params) {
            // setInterval(qq)
            myChart.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: 0,
            });
          });
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: 0,
          });
        }, 3000);

        myChart.currentIndex = -1;

        let qq = setInterval(function () {
          let dataLen = option.series[0].data.length;
          // 取消之前高亮的图形
          myChart.dispatchAction({
            type: "downplay",
            seriesIndex: 0,
            dataIndex: myChart.currentIndex,
          });
          myChart.currentIndex = (myChart.currentIndex + 1) % dataLen;
          // 高亮当前图形
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: myChart.currentIndex,
          });
        }, 3000);

        // 使用刚指定的配置项和数据显示图表。
        /*myChart.setOption(option);*/
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
  },
  mounted() {
    // this.mingsheng();
    // this.drawChart();
  },
};
</script>

<style scoped lang="scss">
.idx-right2-box2 {
  width: 100%;
  height: 27vh;
  //   border: 1px solid tan;
  overflow: hidden;
  margin-top: 6vh;
  // border: 1px solid tan;
  .idx-Rbox-1 {
    font-size: 0.9vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #04f3ee;
    padding-left: 1.3vw;
    padding-top: 1.5vh;
    a {
      color: #04f3ee;
    }
  }
  .idx-Rbox-2 {
    width: 77%;
    height: 4vh;
    line-height: 3vh;
    // border: 1px solid tan;
    font-size: 0.9vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    margin: 0 auto;
    margin-top: 3vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .idx-Rbox-2a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .idx-rs-1 {
        font-size: 1.6vw;
        font-family: hy;
        font-weight: bold;
        font-style: italic;
        color: #ffd901;
      }
      .idx-rs-2 {
        font-size: 0.8vw;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #eeab1a;
      }
    }
  }
  .idx-Rbox-3 {
    height: 21vh;
    padding: 0vh 1vw;
    margin-top: 0vh;
  }
}
</style>
