<template>
  <div class="idx-middle-box3">
    <div class="idx-middle-1">
      <img class="fh" src="@/assets/icon/gb.png" alt="" @click="close" />
      <img class="sx" src="@/assets/icon/sx.png" alt="" @click="refresh" />
    </div>
    <div class="idx-middle-2"></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  components: {},
  computed: {},
  created() {},

  methods: {
    back() {
      this.$router.go(-1); //返回上一层
    },
    refresh() {
      this.$router.go(0); //刷新当前页面
    },
  },
};
</script>

<style scoped lang="scss">
.idx-middle-box3 {
  width: 8vw;
  height: 7vh;
  // border: 1px solid teal;
  position: fixed;
  top: 0vh;
  right: 0vw;
  z-index: 99;
  .idx-middle-1 {
    img {
      width: 3vw;
      height: 6vh;
      cursor: pointer;
    }
  }
  .idx-middle-2 {
  }
}
</style>
