<template>
  <div>
    <div class="container">
      <!-- 背景 -->
      <img
        class="back-ground-image"
        src="@/assets/image/idx-bgzx2_new.png"
        alt=""
      />
      <!-- 全屏事件 -->
      <div
        id="full-page"
        class="full-page"
        v-show="fullPage"
        @click="dialogVisiblebtn($event)"
      >
        <!-- 弹框 -->
        <el-dialog
          title=""
          :visible.sync="dialogVisible"
          width="50%"
          :append-to-body="true"
          :before-close="handleClose"
          :close-on-click-modal="false"
        >
          <div class="dialog-bg">
            <img
              class="back-ground-image"
              src="@/assets/image/idx-dl2.png"
              alt=""
            />
          </div>
          <!--  -->
          <div class="dialog-box">
            <div class="dialog-box-top">
              您没有查看权限，请输入密码才能查看哦
            </div>
            <div class="dialog-box-bottom">
              <div class="login-form">
                <input
                  type="password"
                  v-model="password"
                  placeholder="密码"
                  id="password"
                />
                <button id="login" @click="login">登　录</button>
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
      <!-- 顶部 -->
      <div class="header">数字东泰</div>
      <!-- 关闭 刷新 -->
      <div class="idx-middlesx-box3">
        <div class="idx-middle-1">
          <img
            class="fh"
            src="@/assets/icon/gb.png"
            alt=""
            v-show="gb"
            @click="close"
          />
          <img class="sx" src="@/assets/icon/sx.png" alt="" @click="refresh" />
        </div>
        <div class="idx-middle-2"></div>
      </div>
      <!-- <rightNavindex></rightNavindex> -->
      <!-- <Demo></Demo> -->
      <!-- Left -->
      <div class="idx-Left">
        <!-- 常住人口 -->
        <PermanentPopulation @dialog_click="dialog_click"></PermanentPopulation>
        <!-- 常住人口弹框 -->
        <Dialog
          :dialog="edit_dialog"
          :dialogTitle="dialogTitle"
          :dataList="dataList"
        >
        </Dialog>
        <!-- 党员数据 -->
        <PartymembersData @dialog_click="dialog_click"></PartymembersData>
        <!-- 党员数据弹框 -->
        <Dialog
          :dialog="edit_dialog"
          :dialogTitle="dialogTitle"
          :dataList="dataList"
        >
        </Dialog>
        <!-- 安全防控及康体设施 -->
        <SafetyPrevention @dialog_click="dialog_click"></SafetyPrevention>
        <!-- 安全防控及康体设施弹框 -->
        <Dialog
          :dialog="edit_dialog"
          :dialogTitle="dialogTitle"
          :dataList="dataList"
        >
        </Dialog>
      </div>
      <!-- middle -->
      <div class="idx-Middle">
        <!-- 时间-->
        <Date></Date>
        <!-- 地图 -->
        <index-map></index-map>
        <!-- 社区服务管理 -->
        <div class="idx-Middle-bottom">
          <ShequGldw @dialog_click="dialog_click"></ShequGldw>
          <!-- 社区服务管理弹框 -->
          <Dialog
            :dialog="edit_dialog"
            :dialogTitle="dialogTitle"
            :dataList="dataList"
          >
          </Dialog>
        </div>
        <!-- 社区管理队伍概况 -->
        <!-- <CommunityManagement></CommunityManagement> -->
        <!-- 最新事件 -->
        <!-- <Incident></Incident> -->
      </div>
      <!-- right -->
      <div class="idx-Right">
        <!-- 东泰社区司法调解汇总 -->
        <MediationSummary @dialog_click="dialog_click"></MediationSummary>
        <!-- 东泰社区司法调解汇总弹框 -->
        <Dialog
          :dialog="edit_dialog"
          :dialogTitle="dialogTitle"
          :dataList="dataList"
        >

        </Dialog>
        <!-- 民生警情 -->
        <MingShengjq @dialog_click="dialog_click"></MingShengjq>
        <!-- 民生警情弹框 -->
        <Dialog
          :dialog="edit_dialog"
          :dialogTitle="dialogTitle"
          :dataList="dataList"
        >
        </Dialog>
        <!-- 疫情防控数据 -->
        <EpidemicSituation @dialog_click="dialog_click"></EpidemicSituation>
        <!-- 疫情防控数据弹框 -->
        <Dialog
          :dialog="edit_dialog"
          :dialogTitle="dialogTitle"
          :dataList="dataList"
        >
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Dialog from "@/components/dialog/dialog.vue";
import Demo from "@/views/index/components/demo.vue";
import Date from "@/views/index/components/date.vue";
import IndexMap from "@/views/index/components/indexMap.vue";
import PermanentPopulation from "@/views/index/components/permanentPopulation.vue";
import PartymembersData from "@/views/index/components/partymembersData.vue";
import SafetyPrevention from "@/views/index/components/safetyPrevention.vue";
import CommunityManagement from "@/views/index/components/communityManagement.vue";
import Incident from "@/views/index/components/incident.vue";
import MediationSummary from "@/views/index/components/mediationSummary.vue";
import MingShengjq from "@/views/index/components/mingShengjq.vue";
import EpidemicSituation from "@/views/index/components/epidemicSituation.vue";
import rightNavindex from "@/views/commonComponents/rightNavindex.vue";
import ShequGldw from "@/views/index/components/shequGldw.vue";

export default {
  inject: ["reload"], //注入reload方法
  components: {
    Demo,
    PermanentPopulation,
    PartymembersData,
    SafetyPrevention,
    CommunityManagement,
    Incident,
    MediationSummary,
    MingShengjq,
    EpidemicSituation,
    Date,
    IndexMap,
    rightNavindex,
    ShequGldw,
    Dialog,
  },
  data() {
    return {
      edit_dialog: false,
      dialogTitle: '',
      dataList: [],  // 传递数据

      dialogVisible: false,
      password: undefined,
      // 全屏
      fullPage: true,
      gb: false
    };
  },
  created() {
    // 判断pc iPad
    /*if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
      this.fullPage = false;
      this.dialogVisible = false;
      this.edit_dialog = false
      this.gb = false;
    } else {
      this.cookies();
    }*/
    this.cookies();
  },
  methods: {
    // 弹框出现
    dialog_click(t,e) {
      console.log("实际数据", e);
      if(!this.existSystem()){
        return false;
      }
      this.dialogTitle = t,
      this.dataList = e;
      this.edit_dialog = this.fullPage ? false : true;
    },

    back() {
      this.$router.go(-1); //返回上一层
    },
    refresh() {
      // this.$router.go(0); //刷新当前页面
      this.reload();
    },
    // 详情弹框
    dialogVisiblebtn(e) {
      this.dialogVisible = true;
    },
    handleClose(done) {
      done();
      this.dialogVisible = false;
    },

    // 登录
    login() {
      const pass = this.password; //获取到值

      if (pass ==  this.readPass || pass == this.editPass) {
        this.$message({
          message: "登录成功",
          type: "success",
        });
        this.fullPage = false;
        this.dialogVisible = false;
        this.$cookies.set("pass", pass); //return this
        console.log("获取cookie", this.$cookies.get("pass"));
        this.$router.go(0); //刷新当前页面
      } else {
        this.$message.error("密码错误，请重新输入");
      }
    },
    cookies() {
      // console.log("获取cookie", this.$cookies.get("pass"));
      // 判断cookie
      if (`${this.readPass},${this.editPass}`.indexOf(this.$cookies.get("pass")) > -1 ) {
        this.fullPage = false;
        this.dialogVisible = false;
        this.gb = true;
      } else {
        this.fullPage = true;
        // this.dialogVisible = true;
        this.gb = false;
      }
    },
    // 清除cookies
    close() {
      this.$cookies.remove("pass"); // return this
      this.$router.go(0); //刷新当前页面
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: auto;
  overflow: hidden;
  a {
    font-size: 0.95vw !important;
  }
  .back-ground-image {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
  #full-page {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0.1333vw solid teal;
    background: black;
    opacity: 0;
    margin-top: 0vh;
    z-index: 999;
  }
  // 顶部
  .header {
    width: 100%;
    height: 10vh;
    line-height: 10vh;
    letter-spacing: 4vw;
    padding-left: 1vw;
    // background: linear-gradient(to top, #c0d9ff, #fff);
    background: linear-gradient(to top, #c0d9ff, #fff);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 3vw;
    text-align: center;
    font-family: Microsoft YaHei;
    font-weight: bold;
    // color: #FFFFFF;
    text-shadow: 0px 10px 7px rgba(0, 0, 0, 0.17);
  }
  // 关闭 刷新
  .idx-middlesx-box3 {
    width: 6vw;
    height: 7vh;
    // border: 1px solid teal;
    position: fixed;
    top: 0vh;
    right: 0vw;
    z-index: 99;
    .idx-middle-1 {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      img {
        width: 3vw;
        // height: 6vh;
        height: auto;
        cursor: pointer;
        margin-top: 0.4vh;
        // float: right;
      }
    }
    .idx-middle-2 {
    }
  }
  // Left
  .idx-Left {
    width: 25.59vw;
    height: calc(100% - 10vh);
    margin-right: 2.27vw;
    // border: 1px solid teal;
    float: left;
  }
  .idx-Middle {
    width: 43.8vw;
    margin-right: 1vw;
    height: calc(100% - 10vh);
    float: left;
    // border: 1px solid tan;
    .idx-Middle-bottom {
      width: 100%;
      height: 32vh;
      // border: 1px solid tan;
      margin-top: 6vh;
    }
  }
  .idx-Right {
    margin-right: 0vw;
    width: 23.71vw;
    float: left;
    // border: 1px solid tan;
    margin-left: 2vw;
  }
}
::v-deep .el-dialog {
  position: relative;
  margin: 0 auto 50px;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 99999;
  width: 50%;
  height: 50vh;
  // background: url();
  background: rgba(255, 255, 255, 0);
  z-index: 99999999;
  margin-top: 24vh !important;
  .el-dialog__headerbtn {
    width: 5vw;
    height: 7vh;
    // border: 1px solid teal !important;
    opacity: 0;
    position: absolute;
    top: 7vh;
    right: -2vw;
    padding: 0;
    background: 0 0;
    border: none;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
    z-index: 999999;
  }
  .back-ground-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 8vh;
    left: 1.2vw;
    // border: 1px solid tomato;
  }
  .dialog-box {
    position: absolute;
    top: 10vh;
    left: 1.2vw;
    width: 98%;
    height: 48vh;
    overflow: hidden;
    // border: 1px solid tan;
    .dialog-box-top {
      width: 100%;
      height: 15vh;
      line-height: 16vh;
      text-align: center;
      // border: 1px solid thistle;
      font-size: 0.9vw;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
    .dialog-box-bottom {
      width: 100%;
      height: 20vh;
      // border: 1px solid tan;
      margin-top: 0vh;
      position: absolute;
      top: 17vh;
      left: 0vw;
      .login-form {
        width: 100%;
        height: 20vh;
        // border: 1px solid tan;
        position: absolute;
        top: 0vh;
        left: 0vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        #password {
          width: 74%;
          height: 7vh;
          line-height: 7vh;
          border-radius: 2vw;
          outline: none;
          color: #fff;
          text-indent: 2vw;
          background: #011428;
          border: 3px solid #1863bf;
          font-size: 0.9vw;
        }
        #login {
          width: 74%;
          height: 7vh;
          line-height: 7vh;
          border-radius: 2vw;
          outline: none;
          cursor: pointer;
          font-size: 0.9vw;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #24d8d7;
          background: #032c64;
          border: 3px solid #1863bf;
        }
      }
    }
  }
}
.dialog_form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8vw;
  color: #007eff;
  .form_item {
    display: inline-block;
    width: 16vw;
    height: 6vh;
    display: flex;
    align-items: center;
    label {
      width: 6vw;
      line-height: 1;
      margin-right: 0.5vw;
      text-align: right;
    }
    input {
      height: 4vh;
      width: 12vw;
      background: #011428;
      border: 1px solid #1863bf;
      border-radius: 2px;
      color: #ffffff;
      padding-left: 0.6vw;
    }
  }
}
.dialog_btn {
  // background: red;
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  // top: 30vh;
  bottom: -10vh;
  div {
    display: inline-block;
    width: 9.3vw;
    height: 4.6vh;
    line-height: 4.6vh;
    background: #002953;
    border: 1px solid #1863bf;
    border-radius: 0.2vw;
    color: #24d8d7;
    cursor: pointer;
  }
}
</style>
