<template>
  <div class="idx-middle-xinbox">
    <div class="idx-Lbox-1">
      <router-link tag="a" to="/community">社区服务管理</router-link>
    </div>
    <!--  -->
    <div class="idx-Lbox-2" @click="go">
      <ShequFwnew></ShequFwnew>
      <!-- <div class="idx-Lbox-2L">
        <CommunityManagement></CommunityManagement>
      </div>
      <div class="idx-Lbox-2R">
        <div class="idx-Lbox-2R-T">
          <zhudianWk></zhudianWk>
        </div>
        <div class="idx-Lbox-2R-B">
          <ShequFw></ShequFw>
        </div>
      </div> -->

    </div>
  </div>
</template>

<script>
import CommunityManagement from "@/views/index/components/communityManagement.vue";
// import zhudianWk from "@/views/index/components/zhudianWk.vue";
// import ShequFw from "@/views/index/components/shequFw.vue";
import ShequFwnew from "@/views/index/components/shequFw_new.vue";
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {
    CommunityManagement,
    // zhudianWk,
    // ShequFw,
    ShequFwnew
  },
  props: ["dialog_click"],
  data() {
    return {
      dataOptions: [],
      dataOptions1: [],
      queryParams: {
        dataModule: "sysj",
        dataTyp: "sysj-sqdwgk",
      },
      queryParams1: {
        dataModule: "sqdw",
        dataTyp: "sqdw_sqfwgl",
      },
    };
  },
  created() {
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
    });
    listData(this.queryParams1).then((response) => {
        // console.log("首页",response);
      this.dataOptions1 = response.rows;
    });
  },
  methods: {
    go() {

      let arr = [
        { dataId: '60',label: "街道驻社区团队",dataDetailed: this.getdatavalue(60) },
        { dataId: '321',label: "党群服务中心",dataDetailed: this.getdatavalue(321) },
        { dataId: '322',label: "党建阵地",dataDetailed: this.getdatavalue(322) },
        { dataId: '128',label: "窗口政务服务事项",dataDetailed: this.getdatavalue1(128) },
        { dataId: '62',label: "在册志愿者",dataDetailed: this.getdatavalue(62) },
        { dataId: '323',label: "社会组织",dataDetailed: this.getdatavalue(323) },
        { dataId: '324',label: "自助服务事项",dataDetailed: this.getdatavalue(324) },
        { dataId: '325',label: "成立业委会的住宅小区",dataDetailed: this.getdatavalue(325) },
      ]
      this.$emit("dialog_click","社区服务管理",arr);
    },
    getdatavalue1(id) {
      return getvalue(this.dataOptions1, id);
    },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.idx-middle-xinbox {
  width: 100%;
  height: 18vh;
  // border: 1px solid tan;
  .idx-Lbox-1 {
    font-size: 0.9vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #04f3ee;
    padding-left: 2.5vw;
    padding-top: 1.6vh;
    a {
      color: #04f3ee;
    }
  }
  .idx-Lbox-2 {
    width: 100%;
    height: 16vh;
    // border: 1px solid teal;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3vh;
    .idx-Lbox-2L {
      width: 28%;
      height: 28vh;
    //   border: 1px solid teal;
    }
    .idx-Lbox-2R {
      width: 72%;
      height: 28vh;
    //   border: 1px solid teal;
      .idx-Lbox-2R-T {
        width: 98%;
        height: 10vh;
        // border: 1px solid tan;
      }
      .idx-Lbox-2R-B {
        width: 100%;
        height: 18vh;
        // border: 1px solid teal;
      }
    }
  }
}
</style>
