<template>
  <div class="idx-left-box1">
    <div class="idx-Lbox-1">
      <router-link to="/population">常住人口</router-link>
    </div>
    <div @click="go">
      <div class="idx-Lbox-2">
        <!-- <div class="idx-Lbox-2a">000000000</div> -->
        <div class="idx-Lbox-2b">{{ getdatavalue(2) }}</div>
      </div>
      <div class="idx-Lbox-3">
        <div class="idx-Lbox-3L">
          <div class="idx-Lbox-3L-top">户籍人口</div>
          <div class="idx-Lbox-3L-bt">{{ getdatavalue(4) }}</div>
        </div>
        <div class="idx-Lbox-3R">
          <div class="idx-Lbox-3R-top">辖区面积(k㎡)</div>
          <div class="idx-Lbox-3R-bt">{{ getdatavalue(3) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listData, getvalue } from "@/api/dtdata";
export default {
  components: {},
  props: ["dialog_click"],
  data() {
    return {
      dataOptions: [],
      queryParams: {
        dataModule: "sysj",
        dataTyp: "sysj-rksj",
      },
    };
  },
  created() {
    listData(this.queryParams).then((response) => {
      this.dataOptions = response.rows;
    });
  },
  methods: {
    go() {
      let arr = [
        {
          dataId: '2',
          label: "常住人口",
          dataDetailed: this.getdatavalue(2)
        },
        {
          dataId: '4',
          label: "户籍人口",
          dataDetailed: this.getdatavalue(4),
        },
        {
          dataId: '3',
          label: "辖区面积",
          dataDetailed: this.getdatavalue(3)
        }
      ]
      this.$emit("dialog_click","常住人口",arr);
    },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.idx-left-box1 {
  width: 100%;
  height: 24vh;
  //   border: 1px solid tan;
  .idx-Lbox-1 {
    font-size: 0.9vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #04f3ee;
    padding-left: 2.5vw;
    padding-top: 3vh;
    a {
      color: #04f3ee;
    }
  }
  .idx-Lbox-2 {
    width: 87%;
    height: 7vh;
    line-height: 10vh;
    // border: 1px solid tomato;
    margin: 0 auto;
    position: relative;
    .idx-Lbox-2a {
      font-family: "hy";
      font-weight: bold;
      font-style: italic;
      font-size: 2vw;
      color: #ffd901;
      opacity: 0.1;
      letter-spacing: 1.255vw;
      height: 7vh;
      padding-left: 1.55vw;
      line-height: 8.5vh;
      position: relative;
      z-index: 0;
    }
    .idx-Lbox-2b {
      position: absolute;
      top: 0.5vh;
      left: -9.1vw;
      font-family: "hy";
      font-style: italic;
      font-weight: bold;
      font-size: 2vw;
      color: #ffd901;
      opacity: 1;
      letter-spacing: 1.39vw;
      padding-left: 1.5vw;
      height: 7vh;
      line-height: 7vh;
      text-align: right;
      width: 100%;
      z-index: 999;
      //   display: none;
    }
  }
  .idx-Lbox-3 {
    width: 88%;
    height: 12vh;
    /* line-height: 7vh; */
    // border: 1px solid tan;
    font-size: 2vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #04f3ee;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .idx-Lbox-3L {
      margin-right: 5vw;
      margin-left: 2vw;
      .idx-Lbox-3L-top {
        font-size: 0.8vw;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #04f3ee;
        padding-bottom: 1vh;
      }
      .idx-Lbox-3L-bt {
        font-size: 1.7vw;
        font-family: zcoolqingkehuangyouti;
        font-weight: bold;
        font-style: italic;
        color: #eeab1a;
        font-family: "hy";
      }
    }
    .idx-Lbox-3R {
      // margin-right: 8vw;
      margin-left: 2vw;
      display: flex;
      flex-direction: column;
      .idx-Lbox-3R-top {
        font-size: 0.8vw;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #04f3ee;
        padding-bottom: 1vh;
      }
      .idx-Lbox-3R-bt {
        font-size: 1.7vw;
        font-family: zcoolqingkehuangyouti;
        font-weight: bold;
        font-style: italic;
        color: #eeab1a;
        font-family: "hy";
      }
    }
  }
}
</style>
